$md-autoservice-primary: (
    50 : #e0e7f4,
    100 : #b3c2e3,
    200 : #8099d0,
    300 : #4d70bd,
    400 : #2652af,
    500 : #0033a1,
    600 : #002e99,
    700 : #00278f,
    800 : #002085,
    900 : #001474,
    A100 : #a2acff,
    A200 : #6f7eff,
    A400 : #3c50ff,
    A700 : #2339ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-autoservice-secondary: (
    50 : #fbe5e5,
    100 : #f5bebe,
    200 : #ee9393,
    300 : #e76767,
    400 : #e14747,
    500 : #dc2626,
    600 : #d82222,
    700 : #d31c1c,
    800 : #ce1717,
    900 : #c50d0d,
    A100 : #fff1f1,
    A200 : #ffbebe,
    A400 : #ff8b8b,
    A700 : #ff7272,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary-color: map-get(
  $map: $md-autoservice-primary,
  $key: 500,
);

$accent-color: map-get(
  $map: $md-autoservice-secondary,
  $key: 500,
);
