// snackbar colors based on KDG guidelines
.mat-mdc-snack-bar-container {
  &.snackbar-error {
    --mdc-snackbar-container-color: #dc3545c9 !important;
    --mat-mdc-snack-bar-button-color: black !important;
    --mdc-snackbar-supporting-text-color: black !important;
  }

  &.snackbar-warning {
    --mdc-snackbar-container-color: #ffc107c9 !important;
    --mat-mdc-snack-bar-button-color: black !important;
    --mdc-snackbar-supporting-text-color: black !important;
  }

  &.snackbar-success {
    --mdc-snackbar-container-color: #549e52c9 !important;
    --mat-mdc-snack-bar-button-color: black !important;
    --mdc-snackbar-supporting-text-color: black !important;
  }
}

@media (min-width: 620px) {
  .snackbar-error {
    position: absolute !important;
    bottom: 0px !important;
    right: 0.5em !important;
  }

  .snackbar-warning {
    position: absolute !important;
    bottom: 0px !important;
    right: 0.5em !important;
  }

  .snackbar-success {
    position: absolute !important;
    bottom: 0px !important;
    right: 0.5em !important;
  }
}
