// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "assets/styles/theming/palette";
@import "assets/styles/theming/snackbar";
@import "./assets/styles/utilities/index";
@import "./assets/styles/components/index";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$client-primary: mat.define-palette($md-autoservice-primary);
$client-accent: mat.define-palette($md-autoservice-secondary, A200, A100, A400);

// The warn palette is optional (defaults to red).
$client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-theme: mat.define-light-theme(
  (
    color: (
      primary: $client-primary,
      accent: $client-accent,
      warn: $client-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($client-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

ul {
  list-style-type: none;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;
}

a {
  text-decoration: none;
  color: unset;
}

p {
  margin: 0;
}

button {
  background-color: unset;
  border: unset;
  padding: 0;
  color: var(--neutral-dark);

  &:hover {
    cursor: pointer;
  }
}

.side-dialog {
  min-width: 40vw !important;
  min-height: 100vh;

  &--extra-wide {
    min-width: 80vw !important;
  }

  .mat-mdc-dialog-surface {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    display: grid;
    grid-template-rows: auto 1fr auto;
    max-height: 100vh;

    > *:first-child {
      display: contents;
    }
  }

  .mat-mdc-dialog-content {
    max-height: 85vh;
    overflow-y: auto;
  }

  .mat-mdc-dialog-actions {
    background-color: #fafafa;
    padding: 0.5rem 1.5rem;
    margin-left: -1.5rem;
  }
}

.form-group-row {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: start;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.5rem;
  margin: 0.5rem 0 0.5rem 0;

  &.-top {
    align-items: flex-start;
  }

  &.-has-margin {
    margin-bottom: 1rem;
  }

  &__input {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__input-right {
    grid-column: 2/3;
  }

  &__extra-info {
    grid-column: 1/3;
    grid-row: 2/3;

    color: var(--neutral-dark);
    font-style: italic;
  }
}

fieldset {
  padding: 1rem 0;

  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  min-inline-size: min-content;
  border: none;

  margin-bottom: 1rem;

  border-top: 0.25rem solid var(--primary);

  legend {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding-right: 1rem;

    font-size: 0.875rem;
    line-height: 150%;
    letter-spacing: 1.25px;

    text-transform: uppercase;
  }
}

tr {
  &.progress-row {
    cursor: default;
    height: 4px;
    overflow: hidden;
    td {
      height: 4px;
      margin: 0;
    }
  }
  &.progress-header {
    height: 52px;
    th {
      border-bottom: 0px;
    }
  }
}

mat-row,
mat-footer-row {
  min-height: 30px !important;
  --mdc-checkbox-state-layer-size: 20px;
  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 24px;
    padding: 0;
    margin: 0 18px;
  }
}

mat-header-row {
  min-height: 36px !important;
  --mdc-checkbox-state-layer-size: 20px;
}
