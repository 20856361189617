.sales-table {
  table {
    border-radius: 0.3rem;
    overflow: hidden;

    .checkbox-column {
      width: 50px; /* Adjust this value as needed */
      min-width: 50px;
      max-width: 50px;
    }

    .checkbox-column mat-checkbox {
      padding: 0;
    }

    &.checkboxes-active {
      mat-header-cell:first-of-type,
      mat-cell:first-of-type {
        padding-left: 10px;
      }
    }
  }

  .mat-column-actions,
  .mat-header-actions {
    width: 6rem;
    max-width: 6rem;
  }

  mat-row:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
}

